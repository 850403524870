import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-c_cpp';
import 'ace-builds/src-noconflict/theme-github';
import './CodingCompetition.css';

const CodingCompetition = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [code, setCode] = useState('');
  const [userSubmissions, setUserSubmissions] = useState({});
  const [output, setOutput] = useState('');
  const [testResults, setTestResults] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timeToStart, setTimeToStart] = useState(0);
  const [competitionStatus, setCompetitionStatus] = useState('before');
  const [selectedLanguage, setSelectedLanguage] = useState('python');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Retrieve userId and username from localStorage
  const [userId] = useState(() => localStorage.getItem('userId') || 'anonymous');
  const [username] = useState(() => localStorage.getItem('username') || 'anonymous');

  const languageOptions = [
    { name: 'Python', value: 'python', mode: 'python' },
    { name: 'Java', value: 'java', mode: 'java' },
    { name: 'C', value: 'c', mode: 'c_cpp' },
    { name: 'C++', value: 'cpp', mode: 'c_cpp' },
  ];

  const competitionStartTime = new Date('2025-03-28T10:38:00');
  const competitionDuration = 30 * 60;
  const competitionEndTime = new Date(competitionStartTime.getTime() + competitionDuration * 1000);

  useEffect(() => {
    const storedSubmissions = JSON.parse(localStorage.getItem('userSubmissions') || '{}');
    setUserSubmissions(storedSubmissions);
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('https://practcode-api.com/coding-competition/2025/coding-round/coding-question');
        const formattedQuestions = response.data.problems.map((q) => ({
          id: q.question_id,
          level: q.level,
          question: q.question,
          sampleInput: q.example.input,
          sampleOutput: q.example.output,
          explanation: q.example.explanation,
          constraints: q.constraints,
          testCases: [{ input: q.example.input, expectedOutput: q.example.output }],
        }));
        setQuestions(formattedQuestions);
        setLoading(false);

        const storedSubmissions = JSON.parse(localStorage.getItem('userSubmissions') || '{}');
        if (Object.keys(storedSubmissions).length === formattedQuestions.length) {
          setTimeout(() => {
            window.location.href = '/Codingcompetition/2025/result';
          }, 1000);
        }
      } catch (err) {
        setError('Failed to load coding questions');
        setLoading(false);
        console.error('Error fetching questions:', err);
      }
    };
    fetchQuestions();
  }, []);

  useEffect(() => {
    const updateCompetitionStatus = () => {
      const now = new Date();
      if (now < competitionStartTime) {
        const timeRemaining = Math.floor((competitionStartTime - now) / 1000);
        setTimeToStart(timeRemaining > 0 ? timeRemaining : 0);
        setCompetitionStatus('before');
      } else if (now >= competitionStartTime && now < competitionEndTime) {
        const remaining = Math.floor((competitionEndTime - now) / 1000);
        setTimeLeft(remaining > 0 ? remaining : 0);
        setTimeToStart(0);
        setCompetitionStatus('active');
        if (!questionStartTime && questions.length > 0) {
          setQuestionStartTime(new Date());
        }
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen().catch((err) => {
            console.error('Error entering fullscreen:', err);
          });
        }
      } else if (now >= competitionEndTime) {
        setTimeLeft(0);
        setTimeToStart(0);
        setCompetitionStatus('ended');
        setQuestionStartTime(null);
        if (document.fullscreenElement) {
          document.exitFullscreen().catch((err) => {
            console.error('Error exiting fullscreen:', err);
          });
        }
      }
    };

    updateCompetitionStatus();
    const timer = setInterval(updateCompetitionStatus, 1000);

    const handleFullscreenChange = () => {
      if (competitionStatus === 'active' && !document.fullscreenElement) {
        document.documentElement.requestFullscreen().catch((err) => {
          console.error('Error re-entering fullscreen:', err);
        });
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      clearInterval(timer);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [questionStartTime, questions.length, competitionStatus]);

  const truncateOutput = (text, maxLength = 50) => {
    return text && text.length > maxLength ? `${text.substring(0, maxLength)}... (truncated)` : text || '';
  };

  const handleCodeChange = (newCode) => {
    if (!questionStartTime && competitionStatus === 'active') {
      setQuestionStartTime(new Date());
    }
    setCode(newCode);
  };

  const handleEditorPaste = (e) => {
    if (competitionStatus === 'active') {
      e.preventDefault();
      setOutput('Pasting code is disabled during the competition to prevent cheating.');
    }
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setCode('');
    setOutput('');
    setTestResults([]);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setCode(userSubmissions[questions[currentQuestion + 1]?.id] || '');
      setOutput('');
      setTestResults([]);
      setQuestionStartTime(new Date());
    }
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
      setCode(userSubmissions[questions[currentQuestion - 1]?.id] || '');
      setOutput('');
      setTestResults([]);
      setQuestionStartTime(new Date());
    }
  };

  const handleRunCode = async () => {
    if (competitionStatus !== 'active') {
      setOutput(competitionStatus === 'ended' ? 'Competition has ended!' : 'Competition has not started yet!');
      return;
    }
    if (!code.trim()) {
      setOutput('Please write some code first');
      return;
    }

    setOutput('Running test cases...');
    setTestResults([]);
    try {
      const payload = {
        code,
        language: selectedLanguage,
        username, // Use username from localStorage
        question: questions[currentQuestion].question,
        question_id: questions[currentQuestion].id,
        input: questions[currentQuestion].testCases.map((tc) => tc.input).join('\n'),
        userId, // Add userId from localStorage
      };

      const response = await axios.post(
        'https://practcode-api.com/coding-competition/2025/coding-round/run-testcase',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );

      const { output: testOutput, usercode } = response.data;

      if (testOutput.error && Object.keys(usercode).length === 0) {
        setOutput(`Execution Error:\n${testOutput.error}`);
        return;
      }

      const results = [];
      let index = 0;
      for (const [inputKey, result] of Object.entries(usercode)) {
        const parsedInputArray = JSON.parse(inputKey);
        const inputValues = parsedInputArray[0].split('\n').filter(Boolean);
        const formattedInput = inputValues.join(', ');

        results.push({
          input: formattedInput,
          expectedOutput: truncateOutput(result.expected),
          output: truncateOutput(result.actual),
          passed: testOutput[index.toString()],
        });
        index++;
      }

      setTestResults(results);
      const passedCount = results.filter((r) => r.passed).length;
      const failedCount = results.length - passedCount;
      setOutput(`Public test cases: ${passedCount} passed, ${failedCount} failed. Check results below.`);
    } catch (error) {
      setOutput(`Error: ${error.message} - Check your network or endpoint configuration.`);
      console.error('Run Code Error:', error);
    }
  };

  const handleSubmit = async () => {
    if (competitionStatus !== 'active') {
      setOutput(competitionStatus === 'ended' ? 'Competition has ended!' : 'Competition has not started yet!');
      return;
    }
    if (!code.trim()) {
      setOutput('Please write some code before submitting');
      return;
    }

    if (userSubmissions[questions[currentQuestion].id]) {
      setOutput('This question has already been submitted and cannot be changed.');
      return;
    }

    setShowConfirmModal(true);
  };

  const confirmSubmission = async () => {
    setShowConfirmModal(false);

    const submitTime = new Date();
    const timeInSeconds = questionStartTime ? Math.floor((submitTime - questionStartTime) / 1000) : 0;
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const timeTaken = `${minutes}:${seconds < 10 ? '0' : ''}${seconds} minutes`;

    try {
      const payload = {
        code,
        language: selectedLanguage,
        username, // Use username from localStorage
        question_id: questions[currentQuestion].id,
        time_taken: timeTaken,
        userId, // Add userId from localStorage
      };
      const response = await axios.post(
        'https://practcode-api.com/coding-competition/2025/coding-round/submit-usercode',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );

      const updatedSubmissions = { ...userSubmissions, [questions[currentQuestion].id]: code };
      setUserSubmissions(updatedSubmissions);
      localStorage.setItem('userSubmissions', JSON.stringify(updatedSubmissions));

      const { output: testOutput, usercode } = response.data;
      const results = [];
      let index = 0;
      for (const [inputKey, result] of Object.entries(usercode)) {
        const parsedInputArray = JSON.parse(inputKey);
        const inputValues = parsedInputArray[0].split('\n').filter(Boolean);
        const formattedInput = inputValues.join(', ');

        results.push({
          input: formattedInput,
          expectedOutput: truncateOutput(result.expected),
          output: truncateOutput(result.actual),
          passed: testOutput[index.toString()],
        });
        index++;
      }

      setTestResults(results);
      const passedCount = results.filter((r) => r.passed).length;
      const failedCount = results.length - passedCount;
      setOutput(
        `Submission successful! Time taken: ${timeTaken}. Private test cases: ${passedCount} passed, ${failedCount} failed. ${
          testOutput.error ? `Error: ${testOutput.error}` : ''
        } Check results below.`
      );

      setQuestionStartTime(new Date());

      if (Object.keys(updatedSubmissions).length === questions.length) {
        setTimeout(() => {
          window.location.href = '/Codingcompetition/2025/result';
        }, 1000);
      }
    } catch (error) {
      setOutput(`Submission Error: ${error.response?.data?.message || error.message}`);
      console.error('Submission Error:', error);
    }
  };

  const cancelSubmission = () => {
    setShowConfirmModal(false);
    setOutput('Submission cancelled.');
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 && hours > 0 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`;
  };

  const renderTimeStatus = () => {
    const startStr = competitionStartTime.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    const endStr = competitionEndTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    if (competitionStatus === 'before') {
      return `Starts in ${formatTime(timeToStart)} on ${startStr}`;
    } else if (competitionStatus === 'active') {
      return `Time Left: ${formatTime(timeLeft)} (Ends at ${endStr})`;
    } else {
      return `Ended at ${endStr} on ${startStr.split(',')[0]}`;
    }
  };

  const renderPopupMessage = () => {
    const startDateStr = competitionStartTime.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const startTimeStr = competitionStartTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
    const endTimeStr = competitionEndTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });

    if (competitionStatus === 'before') {
      return (
        <div className="popup-notification">
          <div className="popup-content">
            The Codemaster Coding Competition will start on {startDateStr} at {startTimeStr} and run until {endTimeStr}.
            <br />
            Countdown: {formatTime(timeToStart)}
          </div>
        </div>
      );
    } else if (competitionStatus === 'ended') {
      return (
        <div className="popup-notification">
          <div className="popup-content">
            The Codemaster Coding Competition, held on {startDateStr} from {startTimeStr} to {endTimeStr}, has ended!
            {Object.keys(userSubmissions).length > 0 ? ' Your submissions have been recorded.' : ' No submissions were made.'}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="coding-container">
      <div className="desktop-content">
        {loading ? (
          <>
            <div className="coding-header">
              <h1 className="coding-title">Codemaster Coding Competition</h1>
            </div>
            <div className="coding-content">
              <div className="coding-message">Loading coding questions...</div>
            </div>
            <div className="coding-watermark">Practcode.Ai</div>
          </>
        ) : error ? (
          <>
            <div className="coding-header">
              <h1 className="coding-title">Codemaster Coding Competition</h1>
            </div>
            <div className="coding-content">
              <div className="coding-message">{error}</div>
            </div>
            <div className="coding-watermark">Practcode.Ai</div>
          </>
        ) : (
          <>
            <div className="coding-header">
              <h1 className="coding-title">Codemaster Coding Competition</h1>
              <span className="coding-timer">{renderTimeStatus()}</span>
            </div>
            {competitionStatus === 'active' && (
              <div className="coding-main">
                <div className="coding-question-panel">
                  <div className="coding-question-header">
                    <span className="coding-question-number">
                      Question {currentQuestion + 1} of {questions.length} (ID: {questions[currentQuestion].id})
                    </span>
                    <span className="coding-question-level">Level: {questions[currentQuestion].level}</span>
                  </div>
                  <div className="coding-question-content">
                    <h2 className="coding-question-title">{questions[currentQuestion].question}</h2>
                    <div className="coding-sample">
                      <p>
                        <strong>Example Input:</strong> {questions[currentQuestion].sampleInput}
                      </p>
                      <p>
                        <strong>Example Output:</strong> {questions[currentQuestion].sampleOutput}
                      </p>
                      {questions[currentQuestion].explanation && (
                        <p>
                          <strong>Explanation:</strong> {questions[currentQuestion].explanation}
                        </p>
                      )}
                      {questions[currentQuestion].constraints && (
                        <p>
                          <strong>Constraints:</strong> {questions[currentQuestion].constraints}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="coding-navigation-buttons">
                    <button className="coding-prev-button" onClick={handlePrevious} disabled={currentQuestion === 0}>
                      Previous
                    </button>
                    <button
                      className="coding-next-button"
                      onClick={handleNext}
                      disabled={currentQuestion === questions.length - 1}
                    >
                      Next
                    </button>
                  </div>
                </div>
                <div className="coding-editor-container">
                  <div className="editor-wrapper">
                    <div className="coding-language-selector">
                      <select
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className="coding-language-dropdown"
                      >
                        {languageOptions.map((lang) => (
                          <option key={lang.value} value={lang.value}>
                            {lang.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <AceEditor
                      mode={languageOptions.find((lang) => lang.value === selectedLanguage)?.mode || 'python'}
                      theme="github"
                      value={code}
                      onChange={handleCodeChange}
                      onPaste={handleEditorPaste}
                      name="code-editor"
                      editorProps={{ $blockScrolling: true }}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 2,
                        fontSize:16,
                        showPrintMargin: false,
                      }}
                      className="coding-editor"
                      height="400px"
                      width="100%"
                    />
                  </div>
                  <div className="coding-actions">
                    <button className="coding-run-button" onClick={handleRunCode}>
                      Run Code
                    </button>
                    <button
                      className="coding-submit-button"
                      onClick={handleSubmit}
                      disabled={userSubmissions[questions[currentQuestion].id]}
                    >
                      {userSubmissions[questions[currentQuestion].id] ? '🔒 Submitted' : 'Submit'}
                    </button>
                  </div>
                  {output && (
                    <div className="coding-output">
                      <strong>Output:</strong> <pre>{output}</pre>
                    </div>
                  )}
                  {testResults.length > 0 && (
                    <div className="coding-test-results">
                      <strong>
                        {competitionStatus === 'active' && output.includes('Private') ? 'Private' : 'Public'} Test Case
                        Results:
                      </strong>
                      <ul>
                        {testResults.map((result, index) => (
                          <li key={index} className={result.passed ? 'passed' : 'failed'}>
                            Test Case {index + 1} | Input: {result.input} | Expected: {result.expectedOutput} | Got:{' '}
                            {result.output} | {result.passed ? '✅ Passed' : '❌ Failed'}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
            {renderPopupMessage()}
            {showConfirmModal && (
              <div className="confirm-modal-overlay">
                <div className="confirm-modal">
                  <p>
                    Are you sure you want to submit this question? Once submitted, you cannot make any changes to this
                    question.
                  </p>
                  <div className="confirm-modal-buttons">
                    <button className="confirm-button" onClick={confirmSubmission}>
                      Yes, Submit
                    </button>
                    <button className="cancel-button" onClick={cancelSubmission}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="coding-watermark">Practcode.Ai</div>
          </>
        )}
      </div>
      <div className="mobile-message">This competition can be accessed from computer systems only.</div>
    </div>
  );
};

export default CodingCompetition;