import React from 'react';
import { NavLink } from 'react-router-dom';

const BlogCard = ({ blog }) => {
  return (
    <article className="blog-card">
      <div className="blog-image-wrapper">
        <img src={blog.image} alt={blog.title} className="blog-image" />
        <div className="blog-overlay">
          <div className="blog-category">{blog.category}</div>
        </div>
      </div>
      <div className="blog-content">
        <div className="blog-meta">
          <span className="blog-date">
            <svg className="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2H4C2.89543 2 2 2.89543 2 4V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V4C14 2.89543 13.1046 2 12 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2 6H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {blog.date}
          </span>
          <span className="blog-read-time">
            <svg className="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 4V8L10.5 9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {blog.readTime} min read
          </span>
        </div>
        <h3 className="blog-title">{blog.title}</h3>
        <p className="blog-excerpt">{blog.excerpt}</p>
        <NavLink to={`/blog/${blog.id}`} className="read-more">
          Read Article
          <svg className="arrow-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12L10 8L6 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </NavLink>
      </div>
    </article>
  );
};

export default BlogCard; 