import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './QuizPage.css';

const QuizPage = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [timeToStart, setTimeToStart] = useState(0);
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizEnded, setQuizEnded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actualStartTime, setActualStartTime] = useState(null);

  const quizStartTime = new Date('2025-03-27T11:20:00'); // For testing, adjust to new Date(Date.now() + 60000)
  const quizDuration = 30 * 60; // 30 minutes
  const quizEndTime = new Date(quizStartTime.getTime() + quizDuration * 1000);

  useEffect(() => {
    const isQuizSubmitted = localStorage.getItem('quizsubmitted') === 'true';
    if (isQuizSubmitted) {
      setQuizEnded(true);
      setTimeout(() => {
        window.location.href = '/codingcompetition/2025/coding-round';
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('https://practcode-api.com/coding-competition/2025/quiz-questions');
        const formattedQuestions = response.data.quiz.map((q, index) => ({
          id: index + 1,
          question: q.question,
          options: q.options,
          correctAnswer: null,
        }));
        setQuestions(formattedQuestions);
        setLoading(false);
      } catch (err) {
        setError('Failed to load quiz questions');
        setLoading(false);
        console.error('Error fetching questions:', err);
      }
    };

    fetchQuestions();
  }, []);

  useEffect(() => {
    const updateQuizStatus = () => {
      const now = new Date();
      if (now < quizStartTime) {
        const timeRemaining = Math.floor((quizStartTime - now) / 1000);
        setTimeToStart(timeRemaining > 0 ? timeRemaining : 0);
        setQuizStarted(false);
        setQuizEnded(false);
      } else if (now >= quizStartTime && now < quizEndTime) {
        const remaining = Math.floor((quizEndTime - now) / 1000);
        setTimeLeft(remaining > 0 ? remaining : 0);
        setTimeToStart(0);
        if (!quizStarted) {
          setQuizStarted(true);
          setActualStartTime(now);
        }
        setQuizEnded(false);
        if (!document.fullscreenElement) {
          document.documentElement.requestFullscreen().catch((err) => {
            console.error('Error entering fullscreen:', err);
          });
        }
      } else if (now >= quizEndTime) {
        setTimeLeft(0);
        setTimeToStart(0);
        setQuizStarted(false);
        setQuizEnded(true);
        if (document.fullscreenElement) {
          document.exitFullscreen().catch((err) => {
            console.error('Error exiting fullscreen:', err);
          });
        }
      }
    };

    updateQuizStatus();
    const timer = setInterval(updateQuizStatus, 1000);

    const handleFullscreenChange = () => {
      if (quizStarted && !quizEnded && !document.fullscreenElement) {
        document.documentElement.requestFullscreen().catch((err) => {
          console.error('Error re-entering fullscreen:', err);
        });
      }
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      clearInterval(timer);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [quizStarted, quizEnded]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNext = () => {
    if (selectedOption !== null) {
      setUserAnswers((prev) => ({
        ...prev,
        [questions[currentQuestion].question]: selectedOption,
      }));
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedOption(null);
    } else {
      handleQuizEnd();
    }
  };

  const handleSkip = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedOption(null);
    } else {
      handleQuizEnd();
    }
  };

  const handleQuestionSelect = (index) => {
    setCurrentQuestion(index);
    setSelectedOption(userAnswers[questions[index].question] || null);
  };

  const handleQuizEnd = async () => {
    let finalAnswers = { ...userAnswers };
    if (selectedOption !== null) {
      finalAnswers = {
        ...finalAnswers,
        [questions[currentQuestion].question]: selectedOption,
      };
      setUserAnswers(finalAnswers);
    }

    setQuizEnded(true);

    // Calculate time taken
    const endTime = new Date();
    const timeTakenSeconds = actualStartTime ? Math.floor((endTime - actualStartTime) / 1000) : quizDuration;
    const minutes = Math.floor(timeTakenSeconds / 60);
    const seconds = timeTakenSeconds % 60;
    const timeTaken = `${minutes}:${seconds < 10 ? '0' : ''}${seconds} minutes`;

    // Calculate number of questions attempted
    const questionAttempts = Object.keys(finalAnswers).length;

    // Get userId from localStorage
    const userId = localStorage.getItem('userId');
   

    if (Object.keys(finalAnswers).length > 0) {
      try {
        await axios.post('https://practcode-api.com/coding-competition/2025/submit-answers', {
          answers: finalAnswers,
          time_taken: timeTaken,
          question_attempts: questionAttempts,
          userId: userId || 'anonymous', // Fallback to 'anonymous' if userId is not found
        });
        localStorage.setItem('quizsubmitted', 'true');
        setTimeout(() => {
          window.location.href = '/codingcompetition/2025/coding-round';
        }, 1000);
      } catch (error) {
        console.error('Error submitting quiz answers:', error);
      }
    } else {
      localStorage.setItem('quizsubmitted', 'true');
      setTimeout(() => {
        window.location.href = '/codingcompetition/2025/coding-round';
      }, 1000);
    }
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 && hours > 0 ? '0' : ''}${minutes}:${
      remainingSeconds < 10 ? '0' : ''
    }${remainingSeconds}`;
  };

  const renderTimeStatus = () => {
    const startStr = quizStartTime.toLocaleString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    const endStr = quizEndTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    if (!quizStarted && !quizEnded) {
      return `Starts in ${formatTime(timeToStart)} on ${startStr}`;
    } else if (quizStarted && !quizEnded) {
      return `Time Left: ${formatTime(timeLeft)} (Ends at ${endStr})`;
    } else {
      return `Ended at ${endStr} on ${startStr.split(',')[0]}`;
    }
  };

  const renderPopupMessage = () => {
    const startDateStr = quizStartTime.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const startTimeStr = quizStartTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
    const endTimeStr = quizEndTime.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });

    if (!quizStarted && !quizEnded) {
      return (
        <div className="popup-notification">
          <div className="popup-content">
            The Codemaster Quiz Competition will start on {startDateStr} at {startTimeStr} and run until {endTimeStr}.
            <br />
            Countdown: {formatTime(timeToStart)}
          </div>
        </div>
      );
    } else if (quizEnded) {
      return (
        <div className="popup-notification">
          <div className="popup-content">
            The Quiz Competition, held on {startDateStr} from {startTimeStr} to {endTimeStr}, has ended!{' '}
            {Object.keys(userAnswers).length > 0 ? 'Your submissions have been recorded.' : 'No submissions were made.'}
          </div>
        </div>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <div className="quiz-container">
        <div className="quiz-header">
          <h1 className="quiz-title">Codemaster Quiz Competition</h1>
        </div>
        <div className="quiz-content">
          <div className="quiz-message">Loading quiz questions...</div>
        </div>
        <div className="quiz-watermark">Practcode.Ai</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="quiz-container">
        <div className="quiz-header">
          <h1 className="quiz-title">Codemaster Quiz Competition</h1>
        </div>
        <div className="quiz-content">
          <div className="quiz-message">{error}</div>
        </div>
        <div className="quiz-watermark">Practcode.Ai</div>
      </div>
    );
  }

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <h1 className="quiz-title">Codemaster Quiz Competition</h1>
        <span className="quiz-timer">{renderTimeStatus()}</span>
      </div>

      {quizStarted && !quizEnded && (
        <div className="quiz-main">
          <div className="quiz-content">
            <div className="quiz-question-container">
              <div className="quiz-question-number">
                Question {currentQuestion + 1} of {questions.length}
              </div>
              <div className="quiz-question">{questions[currentQuestion].question}</div>
              <div className="quiz-options">
                {questions[currentQuestion].options.map((option, index) => (
                  <button
                    key={index}
                    className={`quiz-option ${selectedOption === option ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
              <div className="quiz-actions">
                <button className="quiz-skip-button" onClick={handleSkip}>
                  Skip
                </button>
                <button className="quiz-button" onClick={handleNext}>
                  {currentQuestion === questions.length - 1 ? 'Submit' : 'Next'}
                </button>
              </div>
            </div>
          </div>
          <div className="quiz-navigation">
            <h3>Questions</h3>
            <div className="quiz-grid">
              {questions.map((q, index) => (
                <div
                  key={q.id}
                  className={`quiz-grid-item 
                    ${index === currentQuestion ? 'current' : ''} 
                    ${userAnswers[q.question] ? 'submitted' : ''} 
                    ${index < currentQuestion && !userAnswers[q.question] ? 'skipped' : ''}`}
                  onClick={() => handleQuestionSelect(index)}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {renderPopupMessage()}
      <div className="quiz-watermark">Practcode.Ai</div>
    </div>
  );
};

export default QuizPage;