import React from 'react';
import './User_result.css';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from '../../../context/AuthenticatContext';
import endpoints from  '../../../API/endpoints.json';
import Footer from '../../Basic/Footer';

export default function User_result() {
  const { contestName } = useParams();
  const { UserAuthdata } = useContext(UserAuthContext);
  const [resultData, setResultData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          throw new Error('User ID not found');
        }

        const response = await fetch(`https://practcode-api.com/coding-competition/2025/results/competition-results/${userId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch result');
        }
        
        const data = await response.json();
        setResultData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchResult();
  }, [contestName]);

  if (loading) {
    return (
      <div className="result-page">
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading your results...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="result-page">
        <div className="error-container">
          <i className="fa-solid fa-circle-exclamation"></i>
          <h2>Error Loading Results</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="result-page">
        <div className="result-container">
          <div className="result-header">
            <h1>Competition Results</h1>
            <p>{contestName}</p>
          </div>

          <div className="score-section">
            {/* Quiz Score */}
            <div className="score-card">
              <div className="score-header">
                <i className="fa-solid fa-question-circle"></i>
                <h2>Quiz Results</h2>
              </div>
              <div className="score-circle">
                <div className="score-inner">
                  <div className="score-value">{resultData?.quiz?.score || 0}</div>
                  <div className="score-label">Quiz Score</div>
                </div>
                <div className="score-progress">
                  <svg viewBox="0 0 36 36">
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="#e2e8f0"
                      strokeWidth="3"
                    />
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="#2563eb"
                      strokeWidth="3"
                      strokeDasharray={`${(resultData?.quiz?.score / 100) * 100}, 100`}
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Coding Score */}
            <div className="score-card">
              <div className="score-header">
                <i className="fa-solid fa-code"></i>
                <h2>Coding Results</h2>
              </div>
              <div className="score-circle">
                <div className="score-inner">
                  <div className="score-value">{resultData?.coding?.score || 0}</div>
                  <div className="score-label">Coding Score</div>
                </div>
                <div className="score-progress">
                  <svg viewBox="0 0 36 36">
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="#e2e8f0"
                      strokeWidth="3"
                    />
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="#2563eb"
                      strokeWidth="3"
                      strokeDasharray={`${(resultData?.coding?.score / 100) * 100}, 100`}
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="details-section">
            {/* Quiz Details */}
            <div className="details-card">
              <h2>Quiz Performance Details</h2>
              <div className="result-details">
                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-clock"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Time Taken</h3>
                    <p>{resultData?.quiz?.timeTaken || '0'} minutes</p>
                  </div>
                </div>

                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-check-circle"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Correct Answers</h3>
                    <p>{resultData?.quiz?.correctAnswers || 0}</p>
                  </div>
                </div>

                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-times-circle"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Wrong Answers</h3>
                    <p>{resultData?.quiz?.wrongAnswers || 0}</p>
                  </div>
                </div>

                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-question-circle"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Total Questions</h3>
                    <p>{resultData?.quiz?.totalQuestions || 0}</p>
                  </div>
                </div>
              </div>

              <div className="result-summary">
                <h3>Quiz Performance Summary</h3>
                <div className="summary-content">
                  <p>{resultData?.quiz?.summary || 'No summary available.'}</p>
                </div>
              </div>
            </div>

            {/* Coding Details */}
            <div className="details-card">
              <h2>Coding Performance Details</h2>
              <div className="result-details">
                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-clock"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Time Taken</h3>
                    <p>{resultData?.coding?.timeTaken || '0'} minutes</p>
                  </div>
                </div>

                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-code"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Problems Solved</h3>
                    <p>{resultData?.coding?.problemsSolved || 0}/{resultData?.coding?.totalProblems || 0}</p>
                  </div>
                </div>

                <div className="detail-card">
                  <div className="detail-icon">
                    <i className="fa-solid fa-hourglass-half"></i>
                  </div>
                  <div className="detail-content">
                    <h3>Avg. Time/Problem</h3>
                    <p>{resultData?.coding?.averageTimePerProblem || 0} minutes</p>
                  </div>
                </div>
              </div>

              <div className="result-summary">
                <h3>Coding Performance Summary</h3>
                <div className="summary-content">
                  <p>{resultData?.coding?.summary || 'No summary available.'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
