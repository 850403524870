import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TopUsers.css';

const TopUsers = ({ users }) => {
  const navigate = useNavigate();

  const getMedalColor = (position) => {
    switch (position) {
      case 1:
        return '#FFD700'; // Gold
      case 2:
        return '#C0C0C0'; // Silver
      case 3:
        return '#CD7F32'; // Bronze
      default:
        return '#E0E0E0';
    }
  };

  const handleUserClick = (userId, rank) => {
    navigate(`/Codingcompetition/2025/leaderboard/${userId}/${rank}`);
  };

  return (
    <div className="top-users-container">
      <h2>Top Performers</h2>
      <div className="top-users-grid">
        {users.map((user, index) => (
          <div 
            key={user.id} 
            className="top-user-card"
            onClick={() => handleUserClick(user.id, user.rank)}
            style={{ cursor: 'pointer' }}
          >
            <div className="user-rank" style={{ backgroundColor: getMedalColor(index + 1) }}>
              {index + 1}
            </div>
            <div className="user-avatar">
              <img src={user.avatar || `https://ui-avatars.com/api/?name=${user.name}&background=random`} alt={user.name} />
            </div>
            <div className="user-info">
              <h3>{user.name}</h3>
              <p>{user.points} points</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopUsers; 