import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from '../Shared/Navbar';
import "./Courses.css";

const courses = [
  {
    id: 1,
    title: "Java Programming",
    description: "Master Java programming from basics to advanced concepts. Learn object-oriented programming, data structures, and algorithms in Java.",
    duration: "8 Weeks",
    level: "Beginner",
    topics: [
      "Java Basics and OOP",
      "Data Structures in Java",
      "Exception Handling",
      "File I/O",
      "Multithreading",
      "Database Integration"
    ],
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
      </svg>
    )
  },
  {
    id: 2,
    title: "C Programming",
    description: "Learn the fundamentals of C programming language. Understand memory management, pointers, and low-level programming concepts.",
    duration: "6 Weeks",
    level: "Beginner",
    topics: [
      "C Basics",
      "Pointers and Memory",
      "Arrays and Strings",
      "Structures and Unions",
      "File Handling",
      "Dynamic Memory Allocation"
    ],
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3z"/>
        <path d="M12 8v8M8 12h8"/>
      </svg>
    )
  },
  {
    id: 3,
    title: "C++ Programming",
    description: "Advanced C++ programming concepts including object-oriented programming, templates, STL, and modern C++ features.",
    duration: "8 Weeks",
    level: "Intermediate",
    topics: [
      "OOP in C++",
      "Templates and STL",
      "Smart Pointers",
      "Lambda Expressions",
      "Move Semantics",
      "Concurrency"
    ],
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
        <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
      </svg>
    )
  },
  {
    id: 4,
    title: "Python Programming",
    description: "Comprehensive Python programming course covering basics to advanced topics including data science applications.",
    duration: "6 Weeks",
    level: "Beginner",
    topics: [
      "Python Basics",
      "Data Structures",
      "Object-Oriented Python",
      "File Operations",
      "Data Science Basics",
      "Web Scraping"
    ],
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
        <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
        <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
      </svg>
    )
  },
  {
    id: 5,
    title: "Aptitude & Reasoning",
    description: "Master logical reasoning and aptitude skills essential for competitive programming and technical interviews.",
    duration: "4 Weeks",
    level: "All Levels",
    topics: [
      "Logical Reasoning",
      "Verbal Ability",
      "Quantitative Aptitude",
      "Data Interpretation",
      "Problem Solving",
      "Pattern Recognition"
    ],
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
        <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
        <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
        <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
      </svg>
    )
  }
];

const Courses = () => {
  return (
    <div className="courses-page">
      <Navbar />

      {/* Main Content */}
      <main className="main-content">
        {/* Courses Header */}
        <section className="courses-header">
          <h1>Our Courses</h1>
          <p>Explore our comprehensive programming courses designed to help you master coding</p>
        </section>

        {/* Courses Grid */}
        <section className="courses-grid">
          {courses.map(course => (
            <div key={course.id} className="course-card">
              <div className="course-icon">
                {course.icon}
              </div>
              <h2>{course.title}</h2>
              <p className="course-description">{course.description}</p>
              <div className="course-meta">
                <span className="duration">{course.duration}</span>
                <span className="level">{course.level}</span>
              </div>
              <div className="course-topics">
                <h3>What You'll Learn</h3>
                <ul>
                  {course.topics.map((topic, index) => (
                    <li key={index}>{topic}</li>
                  ))}
                </ul>
              </div>
              <NavLink to={`/courses/${course.id}`} className="course-btn">
                Enroll Now
              </NavLink>
            </div>
          ))}
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Practcode Ai</h3>
            <p>Empowering developers through AI-driven learning</p>
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <NavLink to="/user/login">Login</NavLink>
            <NavLink to="/user/signup">Sign Up</NavLink>
            <NavLink to="/Codingcompetition/2025/result">Results</NavLink>
            <NavLink to="/Codingcompetition/2025/leaderboard">Leaderboard</NavLink>
          </div>
          <div className="footer-section">
            <h3>Connect With Us</h3>
            <div className="social-links">
              <a href="#" className="social-link">Twitter</a>
              <a href="#" className="social-link">LinkedIn</a>
              <a href="#" className="social-link">GitHub</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Practcode Ai. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Courses; 