import React from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { blogPosts } from '../../data/blogPosts';
import "./BlogPost.css"

const BlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  // Find the blog post with the matching ID
  const blog = blogPosts.find(post => post.id === parseInt(id));

  // If blog post not found, redirect to home
  if (!blog) {
    navigate('/');
    return null;
  }

  return (
    <article className="blog-post">
      <div className="blog-post-header">
        <div className="blog-post-meta">
          <span className="blog-post-category">{blog.category}</span>
          <span className="blog-post-date">
            <svg className="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2H4C2.89543 2 2 2.89543 2 4V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V4C14 2.89543 13.1046 2 12 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2 6H14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {blog.date}
          </span>
          <span className="blog-post-read-time">
            <svg className="icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 4V8L10.5 9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {blog.readTime} min read
          </span>
        </div>
        <h1 className="blog-post-title">{blog.title}</h1>
        <div className="blog-post-author">
          <span>By {blog.author}</span>
        </div>
      </div>

      <div className="blog-post-image">
        <img src={blog.image} alt={blog.title} />
      </div>

      <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: blog.content }} />

      <div className="blog-post-footer">
        <NavLink to="/" className="back-to-blog">
          <svg className="arrow-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 12L6 8L10 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Back to Blog
        </NavLink>
      </div>
    </article>
  );
};

export default BlogPost; 