import React from 'react';
import './User_leaderboard.css';
import { useParams, Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserAuthContext } from '../../../context/AuthenticatContext';
import endpoints from '../../../API/endpoints.json';
import Footer from '../../Basic/Footer';

export default function User_leaderboard() {
  const { contestName } = useParams();
  const { UserAuthdata } = useContext(UserAuthContext);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch('https://practcode-api.com/coding-competition/2025/results/leaderboard', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch leaderboard');
        }
        
        const result = await response.json();
        if (result.success) {
          setLeaderboardData(result.data);
        } else {
          throw new Error(result.message || 'Failed to fetch leaderboard');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, [contestName]);

  if (loading) {
    return (
      <div className="leaderboard-page">
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading leaderboard...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="leaderboard-page">
        <div className="error-container">
          <i className="fa-solid fa-circle-exclamation"></i>
          <h2>Error Loading Leaderboard</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="leaderboard-page">
        <div className="leaderboard-container">
          <div className="leaderboard-header">
            <h1>Competition Leaderboard</h1>
            <p>{contestName}</p>
          </div>

          {/* Statistics Section */}
          <div className="statistics-section">
            <div className="stat-card">
              <div className="stat-icon">
                <i className="fa-solid fa-users"></i>
              </div>
              <div className="stat-content">
                <h3>Total Participants</h3>
                <p>{leaderboardData?.stats?.totalParticipants || 0}</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <i className="fa-solid fa-question-circle"></i>
              </div>
              <div className="stat-content">
                <h3>Quiz Round Completed</h3>
                <p>{leaderboardData?.stats?.quizCompleted || 0}</p>
                <span className="percentage">
                  {((leaderboardData?.stats?.quizCompleted / leaderboardData?.stats?.totalParticipants) * 100).toFixed(1)}%
                </span>
              </div>
            </div>

            <div className="stat-card">
              <div className="stat-icon">
                <i className="fa-solid fa-code"></i>
              </div>
              <div className="stat-content">
                <h3>Coding Round Completed</h3>
                <p>{leaderboardData?.stats?.codingCompleted || 0}</p>
                <span className="percentage">
                  {((leaderboardData?.stats?.codingCompleted / leaderboardData?.stats?.totalParticipants) * 100).toFixed(1)}%
                </span>
              </div>
            </div>
          </div>

          {/* Leaderboard Table */}
          <div className="leaderboard-table">
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Username</th>
                  <th>Quiz Score</th>
                  <th>Coding Score</th>
                  <th>Total Score</th>
                  <th>Quiz Time</th>
                  <th>Coding Time</th>
                </tr>
              </thead>
              <tbody>
                {leaderboardData?.leaderboard?.map((participant, index) => (
                  <tr key={participant.userId} className={participant.rank <= 3 ? `top-${participant.rank}` : ''}>
                    <td>
                      <div className="rank-badge">
                        {participant.rank === 1 && <i className="fa-solid fa-trophy"></i>}
                        {participant.rank === 2 && <i className="fa-solid fa-medal"></i>}
                        {participant.rank === 3 && <i className="fa-solid fa-award"></i>}
                        {participant.rank > 3 && participant.rank}
                      </div>
                    </td>
                    <td>
                      <Link to={`/Codingcompetition/2025/leaderboard/${participant.userId}/${participant.rank}`} className="username-link">
                        {participant.username}
                      </Link>
                    </td>
                    <td>{participant.quizScore}</td>
                    <td>{participant.codingScore}</td>
                    <td>{participant.totalScore}</td>
                    <td>{participant.quizTime}</td>
                    <td>{participant.codingTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
