import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Leaderboard_details.css';
import Footer from '../../Basic/Footer';

export default function Leaderboard_details() {
  const { userId, rank } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(`https://practcode-api.com/coding-competition/2025/results/leaderboard/user/${userId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        const result = await response.json();
        console.log('API Response:', result); // Debug log

        if (!response.ok) {
          throw new Error(result.message || 'Failed to fetch user details');
        }

        // Check if the response has the expected structure
        if (result && result.data) {
          setUserDetails(result.data);
        } else {
          // If the response structure is different, try to use the result directly
          setUserDetails(result);
        }
      } catch (err) {
        console.error('Error details:', err); // Debug log
        setError(err.message || 'An error occurred while fetching user details');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  if (loading) {
    return (
      <div className="details-page">
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Loading user details...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="details-page">
        <div className="error-container">
          <i className="fa-solid fa-circle-exclamation"></i>
          <h2>Error Loading User Details</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="details-page">
        <div className="details-container">
          <div className="details-header">
            <div className="user-profile">
              <div className="profile-icon">
                <i className="fa-solid fa-user-circle"></i>
              </div>
              <div className="profile-info">
                <h1>{userDetails?.username}</h1>
                <div className="user-details">
                  <span className="detail-item">
                    <i className="fa-solid fa-graduation-cap"></i>
                    {userDetails?.course}
                  </span>
                  <span className="detail-item">
                    <i className="fa-solid fa-users"></i>
                    Section {userDetails?.section}
                  </span>
                  <span className="detail-item">
                    <i className="fa-solid fa-calendar"></i>
                    Year {userDetails?.year}
                  </span>
                </div>
                <div className="rank-badge">
                  Rank #{rank}
                  {userDetails?.rank <= 3 && (
                    <i className={`fa-solid ${userDetails.rank === 1 ? 'fa-trophy' : userDetails.rank === 2 ? 'fa-medal' : 'fa-award'}`}></i>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Quiz Performance Section */}
          <div className="performance-section">
            <h2>Quiz Performance</h2>
            <div className="quiz-summary">
              <div className="summary-card">
                <i className="fa-solid fa-clock"></i>
                <div className="summary-content">
                  <h3>Time Taken</h3>
                  <p>{userDetails?.quizCompetition?.time_taken}</p>
                </div>
              </div>
              <div className="summary-card">
                <i className="fa-solid fa-check-circle"></i>
                <div className="summary-content">
                  <h3>Questions Attempted</h3>
                  <p>{userDetails?.quizCompetition?.question_attempts}/30</p>
                </div>
              </div>
              <div className="summary-card">
                <i className="fa-solid fa-star"></i>
                <div className="summary-content">
                  <h3>Score</h3>
                  <p>{userDetails?.quizCompetition?.score}/30</p>
                </div>
              </div>
            </div>

            <div className="quiz-responses">
              <h3>Quiz Responses</h3>
              <div className="responses-grid">
                {Object.entries(userDetails?.quizCompetition?.["quiz-response"] || {}).map(([question, answer], index) => (
                  <div key={index} className="response-card">
                    <div className="question-number">Q{index + 1}</div>
                    <div className="question-content">
                      <p className="question">{question}</p>
                      <p className={`answer ${answer ? 'answered' : 'unanswered'}`}>
                        {answer || 'Not Attempted'}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Coding Performance Section */}
          <div className="performance-section">
            <h2>Coding Performance</h2>
            {Object.entries(userDetails?.codingCompetition || {}).map(([questionId, details], index) => (
              <div key={index} className="coding-question">
                <div className="question-header">
                  <h3>{questionId}</h3>
                  <div className="question-meta">
                    <span className="time-taken">
                      <i className="fa-solid fa-clock"></i> {details.time_taken}
                    </span>
                    <span className={`score ${details.score > 0 ? 'success' : 'error'}`}>
                      <i className="fa-solid fa-star"></i> Score: {details.score}
                    </span>
                  </div>
                </div>

                <div className="code-section">
                  <div className="code-header">
                    <h4>Solution Code</h4>
                    <button className="copy-btn" onClick={() => navigator.clipboard.writeText(details.code)}>
                      <i className="fa-solid fa-copy"></i> Copy
                    </button>
                  </div>
                  <pre className="code-block">
                    <code>{details.code}</code>
                  </pre>
                </div>

                <div className="testcases-section">
                  <h4>Test Cases</h4>
                  <div className="testcases-grid">
                    {Object.entries(details.expected_status || {}).map(([input, result], idx) => (
                      <div key={idx} className="testcase-card">
                        <div className="testcase-header">
                          <span className="testcase-number">Test Case {idx + 1}</span>
                          <span className={`testcase-status ${result.actual === result.expected ? 'success' : 'error'}`}>
                            {result.actual === result.expected ? 'Passed' : 'Failed'}
                          </span>
                        </div>
                        <div className="testcase-content">
                          <div className="testcase-input">
                            <span className="label">Input:</span>
                            <span className="value">{input.replace(/\\n/g, '\n')}</span>
                          </div>
                          <div className="testcase-output">
                            <span className="label">Expected Output:</span>
                            <span className="value">{result.expected}</span>
                          </div>
                          <div className="testcase-output">
                            <span className="label">Your Output:</span>
                            <span className="value">{result.actual}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
