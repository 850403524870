// Footer.jsx
import React from 'react';
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="practcode-footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-section company-info">
            <h3>Practcode.Ai</h3>
            <p>Empowering developers with AI solutions</p>
          </div>

          <div className="footer-section links">
            <h4>Quick Links</h4>
            <ul>
              <li><a href="">Terms & Conditions</a></li>
              <li><a href="">Privacy Policy</a></li>
              <li><a href="">About Us</a></li>
              <li><a href="">Contact</a></li>
            </ul>
          </div>

          <div className="footer-section social">
            <h4>Connect With Us</h4>
            <div className="social-links">
              <a href="" target="_blank" rel="noopener noreferrer">Twitter</a>
              <a href="" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              <a href="" target="_blank" rel="noopener noreferrer">GitHub</a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© {new Date().getFullYear()} Practcode.Ai. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;