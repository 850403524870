import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="nav-brand">
        <NavLink to="/" className="brand-link">
          <h1>Practcode <span className="brand-ai">Ai</span></h1>
        </NavLink>
      </div>

      {/* Mobile Menu Button */}
      <button className="mobile-menu-btn" onClick={toggleMenu} aria-label="Toggle menu">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          {isMenuOpen ? (
            <path d="M18 6L6 18M6 6l12 12"/>
          ) : (
            <path d="M3 12h18M3 6h18M3 18h18"/>
          )}
        </svg>
      </button>

      {/* Navigation Links */}
      <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
        <NavLink 
          to="/" 
          className={`nav-link ${isActive('/') ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(false)}
        >
          Home
        </NavLink>
        <NavLink 
          to="/courses" 
          className={`nav-link ${isActive('/courses') ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(false)}
        >
          Courses
        </NavLink>
        <NavLink 
          to="/Codingcompetition/2025/result" 
          className={`nav-link ${isActive('/Codingcompetition/2025/result') ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(false)}
        >
          Results
        </NavLink>
        <NavLink 
          to="/Codingcompetition/2025/leaderboard" 
          className={`nav-link ${isActive('/Codingcompetition/2025/leaderboard') ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(false)}
        >
          Leaderboard
        </NavLink>
        <div className="auth-buttons">
          <NavLink 
            to="/user/login" 
            className={`nav-link ${isActive('/user/login') ? 'active' : ''}`}
            onClick={() => setIsMenuOpen(false)}
          >
            Login
          </NavLink>
          <NavLink 
            to="/user/signup" 
            className={`nav-link primary-btn ${isActive('/user/signup') ? 'active' : ''}`}
            onClick={() => setIsMenuOpen(false)}
          >
            Sign Up
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 