export const blogPosts = [
  {
    id: 1,
    title: "Getting Started with Competitive Programming",
    content: `
      <h2>Introduction to Competitive Programming</h2>
      <p>Competitive programming is a mind sport where participants try to program according to provided specifications. It's a great way to improve your problem-solving skills and learn new programming concepts.</p>

      <h2>Why Competitive Programming?</h2>
      <p>Competitive programming offers several benefits:</p>
      <ul>
        <li>Improves problem-solving skills</li>
        <li>Enhances coding speed and efficiency</li>
        <li>Builds strong foundation in algorithms and data structures</li>
        <li>Prepares for technical interviews</li>
      </ul>

      <h2>Getting Started</h2>
      <p>To begin your competitive programming journey:</p>
      <ol>
        <li>Choose a programming language (C++, Python, or Java are popular choices)</li>
        <li>Learn basic data structures and algorithms</li>
        <li>Practice on online platforms like Codeforces, LeetCode, or HackerRank</li>
        <li>Participate in coding competitions</li>
      </ol>
    `,
    image: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    category: "Programming",
    date: "March 15, 2024",
    readTime: 5,
    author: "Practcode Ai"
  },
  {
    id: 2,
    title: "Mastering Data Structures and Algorithms",
    content: `
      <h2>Understanding Data Structures</h2>
      <p>Data structures are fundamental building blocks in computer science that help organize and store data efficiently. They are crucial for writing efficient algorithms and solving complex problems.</p>

      <h2>Essential Data Structures</h2>
      <p>Here are some fundamental data structures every programmer should know:</p>
      <ul>
        <li>Arrays and Strings</li>
        <li>Linked Lists</li>
        <li>Stacks and Queues</li>
        <li>Trees and Graphs</li>
        <li>Hash Tables</li>
      </ul>

      <h2>Common Algorithms</h2>
      <p>Understanding these algorithms is crucial:</p>
      <ul>
        <li>Sorting algorithms (Quick Sort, Merge Sort)</li>
        <li>Searching algorithms (Binary Search)</li>
        <li>Graph algorithms (DFS, BFS)</li>
        <li>Dynamic Programming</li>
        <li>Greedy Algorithms</li>
      </ul>
    `,
    image: "https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    category: "Algorithms",
    date: "March 12, 2024",
    readTime: 8,
    author: "Practcode Ai"
  },
  {
    id: 3,
    title: "Tips for Success in Coding Competitions",
    content: `
      <h2>Preparing for Coding Competitions</h2>
      <p>Success in coding competitions requires a combination of technical skills, strategic thinking, and proper preparation. Here's how you can improve your chances of success.</p>

      <h2>Essential Preparation Steps</h2>
      <ul>
        <li>Master your chosen programming language</li>
        <li>Practice regularly on coding platforms</li>
        <li>Learn from past competitions</li>
        <li>Build a strong foundation in algorithms</li>
        <li>Develop problem-solving strategies</li>
      </ul>

      <h2>Competition Day Tips</h2>
      <p>On the day of the competition:</p>
      <ul>
        <li>Start with easier problems</li>
        <li>Read all problems carefully</li>
        <li>Manage your time effectively</li>
        <li>Test your solutions thoroughly</li>
        <li>Stay calm under pressure</li>
      </ul>
    `,
    image: "https://images.unsplash.com/photo-1504384764586-bb4cdc1707b0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    category: "Tips",
    date: "March 10, 2024",
    readTime: 6,
    author: "Practcode Ai"
  }
]; 