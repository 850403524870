import React from "react";
import "./Signup.css";
import { useParams, Navigate, NavLink } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthenticatContext, UserAuthContext } from "../../context/AuthenticatContext";
import Cookies from 'js-cookie';
import endpoints from "../../API/endpoints.json"
import Footer from "../Basic/Footer";

export default function Signup() {
  console.log(endpoints.server1)
  // AUTHENTICATION PARAMETERS
  const { contestName } = useParams();
  const { authentication_data, update_authentication } = useContext(AuthenticatContext);
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext);
  const { isSignuped, setisSignuped } = useState(false)
  const [username, setInputusername] = useState("");
  const [password, setInputpassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [course, setInputcourse] = useState("IMCA");
  const [year, setInputyear] = useState("1");
  const [section, setInputsection] = useState("A");

  const [responseError, setError] = useState({
    msg: "",
    flag: { color: "green" },
  });
  const [isloading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const setCookie = (name, value, hoursToExpire, userData) => {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + hoursToExpire * 60 * 60 * 1000
    );
    const cookieValue = `${name}=${encodeURIComponent(
      value
    )};expires=${expirationDate.toUTCString()};path=/`;

    // Store userData in a cookie
    document.cookie = cookieValue;
    Cookies.set('userData', JSON.stringify(userData), { expires: hoursToExpire / 24 });


    // Save userId and username in localStorage
    localStorage.setItem('userId', userData['id']);
    localStorage.setItem('username', userData['user']);

    // update the Authcontext-states
    update_UserAuthData({ "userId": userData['id'], "username": userData['user'] });
    update_authentication({ authtoken: value, isLoggedIn: true });
  };

  const signup = async (e) => {
    setloading(true);
    e.preventDefault();
    console.log({ "name": username, "email": email, "phone": phone, "course": course, "year": year, "password": password, "section": section,"hackfestData":{"ContestName":"codeMaster"}});

    const response = await fetch(`${endpoints.server1}/user/auth/newuser`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "name": username, "email": email, "phone": phone, "course": course, "year": year, "password": password, "section": section ,"hackfestData":{"ContestName":"codeMaster"} })
    });
    const data = await response.json();
    setloading(false);
    console.log(response);
    if (response.ok) {
      setCookie('authToken', data.authToken, 2, data);
      setError({ "msg": "Welcome to practcode !", "flag": { color: "green" } });
    } else {
      setError({ "msg": `${data.errors[0]['msg']}`, "flag": { color: "red" } });
    }
  };

  if (authentication_data.isLoggedIn) {
    return <Navigate to={`/codingcompetition/2025/quiz-round`} />;
  }

  return (
    <>
      <div className="signup-page">
        <div className="signup-container">
          <div className="signup-content">
            <div className="signup-header">
              <div className="logo">
                <span className="logo-text">Practcode</span>
                <span className="logo-accent">.AI</span>
              </div>
              <h1>Create Account</h1>
              <p>Join our coding community and start your journey</p>
            </div>

            <div className="signup-form-container">
              {responseError.msg && (
                <div className={`alert ${responseError.flag.color === "green" ? "alert-success" : "alert-error"}`}>
                  {responseError.msg}
                </div>
              )}

              <form onSubmit={signup} className="signup-form">
                <div className="form-section">
                  <h2>Personal Information</h2>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => setInputusername(e.target.value)}
                      placeholder="Enter your username"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="tel"
                      id="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Enter 10-digit phone number"
                      pattern="\d{10}"
                      maxLength="10"
                      required
                    />
                  </div>
                </div>

                <div className="form-section">
                  <h2>Academic Details</h2>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="course">Course</label>
                      <select
                        id="course"
                        value={course}
                        onChange={(e) => setInputcourse(e.target.value)}
                        required
                      >
                        <option value="IMCA">IMCA</option>
                        <option value="MCA">MCA</option>
                        <option value="BCA">BCA</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="year">Year</label>
                      <select
                        id="year"
                        value={year}
                        onChange={(e) => setInputyear(e.target.value)}
                        required
                      >
                        <option value="1">1st Year</option>
                        <option value="2">2nd Year</option>
                        <option value="3">3rd Year</option>
                        <option value="4">4th Year</option>
                        <option value="5">5th Year</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="section">Section</label>
                      <select
                        id="section"
                        value={section}
                        onChange={(e) => setInputsection(e.target.value)}
                        required
                      >
                        <option value="A">Section A</option>
                        <option value="B">Section B</option>
                        <option value="C">Section C</option>
                        <option value="D">Section D</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-section">
                  <h2>Security</h2>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setInputpassword(e.target.value)}
                        placeholder="Create a password"
                        required
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
                      </button>
                    </div>
                  </div>
                </div>

                <button type="submit" className="submit-button" disabled={isloading}>
                  {isloading ? (
                    <div className="loader">
                      <div className="spinner"></div>
                      <span>Creating Account...</span>
                    </div>
                  ) : (
                    "Create Account"
                  )}
                </button>

                <div className="form-footer">
                  <p>
                    Already have an account?{" "}
                    <NavLink to="/user/login" className="login-link">
                      Sign In
                    </NavLink>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
