import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
// import "./index.css"

import QuizPage from './Components/HackFest2025/QuizPage'
import CodingCompetition from './Components/HackFest2025/CodingCompetition'
import Login from './Components/User/Login'

import AuthenticatState from './context/AuthenticatState';
import Signup from './Components/User/Signup'
import Home from './Components/Pages/Home'

import User_result from "./Components/HackFest2025/Result/User_result"
import User_leaderboard from "./Components/HackFest2025/Result/User_leaderboard"
import Leaderboard_details from "./Components/HackFest2025/Result/Leaderboard_details"
import BlogPost from './Components/Blog/BlogPost'
import Courses from './Components/Pages/Courses'
const App = () => {
  return (

    <>
      <AuthenticatState>
        <BrowserRouter>
          <Routes>
            {/* user */}
            <Route path='user/login' element={<Login />}/>
            <Route path='user/signup' element={<Signup />}/>

            {/* Pages */}
            <Route path='/' element={<Home />}/>
            <Route path='/courses' element={<Courses />}/>


            {/* Competition 2025 */}
             <Route path='codingcompetition/2025/quiz-round' element={<QuizPage />}/>
             <Route path='codingcompetition/2025/coding-round' element={<CodingCompetition/>}/>

             <Route path='Codingcompetition/2025/result' element={<User_result/>}/>
             <Route path='Codingcompetition/2025/leaderboard' element={<User_leaderboard/>}/>
             <Route path="Codingcompetition/2025/leaderboard/:userId/:rank" element={<Leaderboard_details />} />

             {/* Blog  Posts*/}
             <Route path="/blog/:id" element={<BlogPost />} />

          </Routes>
        </BrowserRouter>
        </AuthenticatState>
    </>
  )
}

export default App
