import React, { useState, useEffect } from 'react'
import "./Home.css"
import { NavLink } from 'react-router-dom'
import { HomeIcon, ResultsIcon, LeaderboardIcon, ProfileIcon } from '../Icons'
import BlogCard from '../Blog/BlogCard'
import { blogPosts } from '../../data/blogPosts'
import Navbar from '../Shared/Navbar'
import ChatBox from '../Chat/ChatBox'
import TopUsers from '../HackFest2025/Result/TopUsers'

const Home = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [topUsers, setTopUsers] = useState([])

  useEffect(() => {
    // Fetch top 3 users from the leaderboard
    const fetchTopUsers = async () => {
      try {
        const response = await fetch('/api/leaderboard/top-3')
        const data = await response.json()
        setTopUsers(data)
      } catch (error) {
        console.error('Error fetching top users:', error)
        // Set some mock data for demonstration
        setTopUsers([
          {
            id: "67e4e276afd2c8c87ea7f367",
            name: 'sonali',
            points: 89,
            avatar: 'https://ui-avatars.com/api/?name=sonali&background=random',
            rank:1
          },
          {
            id: "67e4e28fafd2c8c87ea7f381",
            name: 'sakshipandey',
            points: 88,
            avatar: 'https://ui-avatars.com/api/?name=sakshipandey&background=random',
            rank:2
          },
          {
            id: "67e4e27eafd2c8c87ea7f36f",
            name: 'Priyanka',
            points: 72,
            avatar: 'https://ui-avatars.com/api/?name=Priyanka&background=random',
            rank:3
          }
        ])
      }
    }

    fetchTopUsers()
  }, [])

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen)
  }

  return (
    <div className="home-page">
      <Navbar />

      {/* Main Content */}
      <main className="main-content">
        {/* Hero Section */}
        <section className="hero-section">
          <div className="hero-content">
            <div className="hero-text">
              <h1>Master Programming with <span className="highlight">AI-Powered Learning</span></h1>
              <p>Join our community of developers and enhance your coding skills with personalized learning paths and real-world projects.</p>
              <div className="hero-buttons">
                <NavLink to="/user/signup" className="primary-btn">
                  Get Started
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M5 12h14M12 5l7 7-7 7"/>
                  </svg>
                </NavLink>
                <NavLink to="/courses" className="secondary-btn">
                  Explore Courses
                </NavLink>
              </div>
            </div>
            <div className="hero-stats">
              <div className="stat-card">
                <h3>1500+</h3>
                <p>Active Users</p>
              </div>
              <div className="stat-card">
                <h3>100+</h3>
                <p>Coding Contests</p>
              </div>
              <div className="stat-card">
                <h3>4+</h3>
                <p>Programming Courses</p>
              </div>
            </div>
          </div>
        </section>

        {/* Top Users Section */}
        <TopUsers users={topUsers} />

        {/* Features Section */}
        <section className="features-section">
          <h2>Why Choose Practcode Ai?</h2>
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">
                <HomeIcon />
              </div>
              <h3>AI-Powered Learning</h3>
              <p>Personalized learning paths adapted to your skill level and goals</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <LeaderboardIcon />
              </div>
              <h3>Competitive Programming</h3>
              <p>Regular coding competitions to test and improve your skills</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <ResultsIcon />
              </div>
              <h3>Comprehensive Courses</h3>
              <p>Structured courses covering all major programming languages</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <ProfileIcon />
              </div>
              <h3>Community Support</h3>
              <p>Join a community of learners and get help when you need it</p>
            </div>
          </div>
        </section>

        {/* Courses Section */}
        <section className="courses-section">
          <div className="section-header">
            <h2>Popular Courses</h2>
            <NavLink to="/courses" className="view-all">
              View All Courses
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="arrow-icon">
                <path d="M5 12h14M12 5l7 7-7 7"/>
              </svg>
            </NavLink>
          </div>
          <div className="courses-grid">
            <div className="course-card">
              <div className="course-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                </svg>
              </div>
              <h3>Java Programming</h3>
              <p>Master Java from basics to advanced concepts</p>
              <div className="course-meta">
                <span>8 Weeks</span>
                <span>Beginner</span>
              </div>
              <a href="/courses/java" className="course-btn">Start Learning</a>
            </div>

            <div className="course-card">
              <div className="course-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3z"/>
                  <path d="M12 8v8M8 12h8"/>
                </svg>
              </div>
              <h3>C Programming</h3>
              <p>Learn the fundamentals of C programming</p>
              <div className="course-meta">
                <span>6 Weeks</span>
                <span>Beginner</span>
              </div>
              <a href="/courses/c" className="course-btn">Start Learning</a>
            </div>

            <div className="course-card">
              <div className="course-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                  <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
                </svg>
              </div>
              <h3>C++ Programming</h3>
              <p>Advanced C++ concepts and OOP principles</p>
              <div className="course-meta">
                <span>8 Weeks</span>
                <span>Intermediate</span>
              </div>
              <a href="/courses/cpp" className="course-btn">Start Learning</a>
            </div>

            <div className="course-card">
              <div className="course-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                  <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
                  <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
                </svg>
              </div>
              <h3>Python Programming</h3>
              <p>Python for beginners and data science</p>
              <div className="course-meta">
                <span>6 Weeks</span>
                <span>Beginner</span>
              </div>
              <a href="/courses/python" className="course-btn">Start Learning</a>
            </div>

            <div className="course-card">
              <div className="course-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                  <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
                  <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
                  <path d="M12 12l-5 2.5L12 17l5-2.5L12 12z"/>
                </svg>
              </div>
              <h3>Aptitude & Reasoning</h3>
              <p>Master logical reasoning and aptitude</p>
              <div className="course-meta">
                <span>4 Weeks</span>
                <span>All Levels</span>
              </div>
              <a href="/courses/aptitude" className="course-btn">Start Learning</a>
            </div>
          </div>
        </section>

        {/* Blog Section */}
        <section className="blog-section">
          <div className="section-header">
            <h2>Latest from Our Blog</h2>
            <NavLink to="/blog" className="view-all">
              View All Posts
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="arrow-icon">
                <path d="M5 12h14M12 5l7 7-7 7"/>
              </svg>
            </NavLink>
          </div>
          <div className="blog-grid">
            {blogPosts.map(blog => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </div>
        </section>

        {/* Competition Banner */}
        <section className="competition-banner">
          <div className="banner-content">
            <h2>Join Our Next Coding Competition</h2>
            <p>Test your skills against other developers and win exciting prizes</p>
            <NavLink to="/Codingcompetition/2025" className="primary-btn">
              Register Now
            </NavLink>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Practcode Ai</h3>
            <p>Empowering developers through AI-driven learning</p>
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <NavLink to="/user/login">Login</NavLink>
            <NavLink to="/user/signup">Sign Up</NavLink>
            <NavLink to="/Codingcompetition/2025/result">Results</NavLink>
            <NavLink to="/Codingcompetition/2025/leaderboard">Leaderboard</NavLink>
          </div>
          <div className="footer-section">
            <h3>Connect With Us</h3>
            <div className="social-links">
              <a href="#" className="social-link">Twitter</a>
              <a href="#" className="social-link">LinkedIn</a>
              <a href="#" className="social-link">GitHub</a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Practcode Ai. All rights reserved.</p>
        </div>
      </footer>

      {/* Mobile Bottom Navigation */}
      <div className="mobile-bottom-nav">
        <NavLink to="/" className="mobile-nav-item">
          <HomeIcon />
          <span>Home</span>
        </NavLink>
        <NavLink to="/Codingcompetition/2025/result" className="mobile-nav-item">
          <ResultsIcon />
          <span>Results</span>
        </NavLink>
        <NavLink to="/Codingcompetition/2025/leaderboard" className="mobile-nav-item">
          <LeaderboardIcon />
          <span>Leaderboard</span>
        </NavLink>
        <NavLink to="/user/profile" className="mobile-nav-item">
          <ProfileIcon />
          <span>Profile</span>
        </NavLink>
      </div>

      {/* AI Chat Support Button */}
      <button className="ai-chat-button" onClick={toggleChat}>
        <div className="ai-chat-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"/>
          </svg>
        </div>
        <span className="ai-chat-label">Chat with AI</span>
      </button>

      {/* ChatBox Component */}
      <ChatBox isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </div>
  )
}

export default Home